/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { H5PPlayerUI } from '@lumieducation/h5p-react';
import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import {
  IContentService,
  ContentService,
} from '../H5p/services/ContentService';

axios.defaults.headers.common['X-CSRF-TOKEN'] =
  ReactOnRails.authenticityToken() as string;
axios.defaults.headers.common.Accept = 'application/json';

const InteractiveVideo = (props) => {
  const [loading, setLoading] = useState<Boolean>(true);
  const [interactionType, setInteractionType] = useState<String>('');
  const contentService: IContentService = new ContentService('/h5p');
  const lessonTypeNotVideo = [
    'drag_and_drop',
    'quiz',
    'fill_blank',
    'mark_words',
    'column',
    'drag_text',
  ];

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const saveAnswer = async (params) => {
    const res = await axios.put(props.saveAnswerUrl, { params });
  };

  const getScore = (result: any, targetRsult: any) => {
    targetRsult = targetRsult?.replace(/[^\d\,]*/g, '').split(',');
    result = result?.replace(/[^\d\,]*/g, '').split(',');
    let score = 0;

    if (result[0].length !== 0) {
      const difference = result.filter((x) => !targetRsult.includes(x));
      score = targetRsult.length - difference.length;
    }
    return [score, targetRsult.length]; // get score and max_score
  };

  const splitAnswersFromString = (ans) => ans.split('[,]');

  const getAnswerFromMarkWords = (origin, results) => {
    const studentAnswerResults: any[] = [];
    const studentAnswerIDs = splitAnswersFromString(results);
    studentAnswerIDs.forEach((studentAnswerID) => {
      const description = origin.find((x) => x.id === studentAnswerID)
        ?.description['en-US'];
      studentAnswerResults.push(description);
    });

    return studentAnswerResults.join('[,]');
  };

  const parseData = (statement: any, context: any, event) => {
    const { result, object } = statement;
    const { id } = object;

    if (props.saveAnswerUrl && result) {
      const { response, success, type } = result;
      if (object.definition?.interactionType === 'matching') {
        const scoreInfo = getScore(
          result.response,
          object.definition?.correctResponsesPattern[0],
        );
        const timeSpent = result?.duration?.slice(2, -1);
        saveAnswer({
          type: 'DragQuestion',
          subcontent_id: id.split('?subContentId=').pop(),
          time_spent: timeSpent,
          score: scoreInfo[0],
          max_score: scoreInfo[1],
        });
      } else if (
        object.definition?.interactionType === 'fill-in' &&
        ['fill_blank', 'quiz', 'column'].includes(props.lesson_type)
      ) {
        const { score } = result;
        const { raw, max } = score;
        const subContentId = id.includes('?subContentId=')
          ? id.split('?subContentId=').pop()
          : id;
        saveAnswer({
          type: 'FillBlanks',
          time_spent: result?.duration?.slice(2, -1),
          score: raw,
          max_score: max,
          answers: response,
          subContentId,
          question_type: 'fill_blank',
        });
      } else if (
        object.definition?.interactionType === 'choice' &&
        ['mark_words', 'quiz', 'column'].includes(props.lesson_type) &&
        statement.context.contextActivities.category[0].id.includes(
          'MarkTheWords',
        )
      ) {
        const { choices, correctResponsesPattern } = object.definition;
        const { score } = result;
        const { raw, max } = score;
        const answers = getAnswerFromMarkWords(choices, response);
        const subContentId = id.includes('?subContentId=')
          ? id.split('?subContentId=').pop()
          : id;

        saveAnswer({
          type: 'MarkWords',
          time_spent: result?.duration?.slice(2, -1),
          score: raw,
          max_score: max,
          answers,
          subContentId,
          question_type: 'mark_words',
          response,
          correct_response: correctResponsesPattern[0],
        });
      } else if (
        props.lesson_type === 'memory_game' &&
        statement.context.contextActivities.category[0].id.includes('MemoryGame')
      ) {
        const { score } = result;
        const { raw, max } = score;
        const subContentId = id.includes('?subContentId=')
          ? id.split('?subContentId=').pop()
          : id;
        saveAnswer({
          type: 'MemoryGame',
          time_spent: result?.duration?.slice(2, -1),
          score: raw,
          max_score: max,
          subContentId,
          question_type: 'memory_game'
        });
      } else if (
        object.definition?.interactionType === 'fill-in' &&
        ['drag_text', 'quiz', 'column'].includes(props.lesson_type)
      ) {
        saveAnswer({
          type: 'DragText',
          time_spent: result?.duration?.slice(2, -1),
          score: result.score.raw,
          max_score: result.score.max,
          question_type: 'drag_text',
          answers: result.response,
        });
      } else {
        const subContentId = id.split('?subContentId=').pop();
        saveAnswer({ success, response, subContentId, type: 'choice' });
      }
    }
    return object.definition;
  };

  return (
    <div className={loading ? 'loading' : ''}>
      <div
        className={`col-12  ${
          loading
            ? '--loading'
            : `${
                lessonTypeNotVideo.includes(props.lesson_type)
                  ? 'fill-blank p-3'
                  : 'p-0 my-courses__video'
              }`
        } `}
      >
        <H5PPlayerUI
          contentId={props.id}
          loadContentCallback={contentService.getPlay}
          onInitialized={onPlayerInitialized}
          onxAPIStatement={(statement: any, context: any, event) => {
            parseData(statement, context, event);
          }}
        />
        {loading && (
          <div
            className={`spinner-border spinner-border-sm m-2`}
            role="status"
          ></div>
        )}
      </div>
    </div>
  );
};

export default InteractiveVideo;
