/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import { H5PEditorUI } from '@lumieducation/h5p-react';
import { Alert } from 'react-bootstrap';
import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import {
  IContentService,
  ContentService,
} from '../H5p/services/ContentService';
import {
  parseData,
  parseToQuestion,
  parseFromQuiz,
  parseFromMultipleChoice,
  parseFromFillBlanks,
  parseFromMarkTheWords,
  parseFromDragText,
  parseColumnType,
} from './ParseData';

axios.defaults.headers.common['X-CSRF-TOKEN'] =
  ReactOnRails.authenticityToken() as string;
axios.defaults.headers.common.Accept = 'application/json';

const EditorVideo = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<any>();

  const contentService: IContentService = new ContentService('/h5p');
  const h5pEditor: React.RefObject<H5PEditorUI> = React.createRef();
  const onEditorLoaded = () => {
    setLoading(false);
  };

  const save = async () => {
    setSaving(true);
    try {
      const returnData: any = await h5pEditor.current?.save();
      const { metadata } = returnData;
      let lessonType;
      let data;

      switch (metadata.mainLibrary) {
        case 'H5P.MemoryGame':
          lessonType = 'memory_game';
          break;
        case 'H5P.DragQuestion':
          lessonType = 'drag_and_drop';
          break;
        case 'H5P.QuestionSet':
          lessonType = 'quiz';
          data = parseFromQuiz(returnData.questions);
          break;
        case 'H5P.Blanks':
          lessonType = 'fill_blank';
          data = parseFromFillBlanks(
            returnData.questions,
            returnData?.subContentId,
          );
          break;
        case 'H5P.MarkTheWords':
          lessonType = 'mark_words';
          const { params, subContentId } = returnData;
          const { taskDescription, textField } = params;
          data = parseFromMarkTheWords(
            taskDescription,
            textField,
            subContentId,
          );
          break;
        case 'H5P.Column':
          lessonType = 'column';
          data = parseColumnType(returnData.params.content);
          break;
        case 'H5P.DragText':
          lessonType = 'drag_text';
          const question = returnData.params.taskDescription;
          const description = returnData.params.textField;
          data = parseFromDragText(
            question,
            description,
            returnData?.subContentId,
          );
          break;
        default: // H5P.MultiChoice
          lessonType = 'choice';
          data = parseFromMultipleChoice(
            returnData.params,
            returnData?.subContentId,
          );
      }

      if (returnData.interactions) {
        const interactions = returnData.interactions?.filter((interaction) => {
          if (
            interaction.action?.metadata.contentType === 'Navigation Hotspot'
          ) {
            const infomation = interaction.action.params.information;
            return infomation.numberOfQuestion && infomation.value;
          }
          return ['Multiple Choice', 'Drag and Drop'].includes(
            interaction.action.metadata.contentType,
          );
        });

        if (interactions && interactions.length > 0) {
          data = parseData(interactions);
          data = parseToQuestion(data);
        }
      }

      if (returnData) {
        const res = await axios.put(props.updateEditorUrl, {
          params: {
            content_id: returnData.contentId,
            data,
            lesson_type: lessonType,
          },
        });
        if (res.status === 200) {
          setVariant('success');
          setMessage(res.data.message);
          setTimeout(() => {
            window.location.href = props.updateQuestionsUrl;
          }, 1000);
        } else {
          setVariant('danger');
          setMessage("Oh dear. Something's wrong.");
          setTimeout(() => {
            setMessage('');
          }, 3000);
        }
      }
    } catch (res: any) {
      console.log(res);
      setVariant('danger');
      setMessage(res.response?.data?.error || "Oh dear. Something's wrong.");
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }
  };

  const onSaved = async (event) => {
    setSaving(false);
  };

  const onSaveError = async (event) => {
    setMessage(event);
    setSaving(false);
    setTimeout(() => {
      setMessage('');
    }, 5000);
  };

  const [variant, setVariant] = useState('danger');

  return (
    <>
      {message && (
        <Alert
          style={{ position: 'fixed', top: 20, right: 20 }}
          variant={variant}
        >
          {message}
        </Alert>
      )}
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 11 }}>
        <div
          className="toast"
          aria-atomic="true"
          aria-live="assertive"
          role="alert"
          data-bs-delay="2000"
        >
          <div className="alert alert-error mb-0" role="alert">
            alert
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 mb-3">
          <p className="fw-bold">Interactive Video Editor</p>
          <div className={props.id !== 'new' && loading ? 'loading' : ''}>
            <H5PEditorUI
              ref={h5pEditor}
              contentId={props.id}
              loadContentCallback={contentService.getEdit}
              saveContentCallback={contentService.save}
              onSaved={onSaved}
              onLoaded={onEditorLoaded}
              onSaveError={onSaveError}
            />
          </div>
        </div>
        <div className="col-12 col-md-2">
          <a href={props.backUrl}>
            <button className="btn-shadow btn-shadow--gray fw-bold w-100 mb-3">
              <i aria-hidden="true" className="fas fa-arrow-left me-2"></i>Back
            </button>
          </a>
        </div>
        <div className="col-12 col-md-4 offset-md-2">
          <button
            // disabled={saving || loading}
            onClick={save}
            className="btn-shadow btn-shadow--golden fw-bold w-100"
          >
            {saving ? <div className="spinner-border"></div> : 'Confirm'}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditorVideo;
