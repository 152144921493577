/* eslint-disable */

import { debug } from 'console';

export const parseData = (interactions) =>
  interactions.map((interaction, i) => {
    const { action, duration } = interaction;
    const { params, metadata } = action;
    const { contentType } = metadata;
    if (contentType === 'Navigation Hotspot') {
      const { information } = params;
      return {
        type: 'answer',
        content_type: 'Choice',
        order: information.numberOfQuestion,
        description: information.value,
        subcontent_id: action?.subContentId,
        is_correct: information.correct,
        timing: parseInt(duration?.from),
      };
    }

    if (contentType === 'Multiple Choice') {
      return {
        type: 'question',
        description: params.question,
        subcontent_id: action.subContentId,
        order: i + 1,
        content_type: 'Choice',
        timing: parseInt(duration.from),
        answers: params.answers.map((answer, index) => ({
          is_correct: answer.correct,
          description: answer.text,
          order: index + 1,
        })),
      };
    }
    if (contentType === 'Drag and Drop') {
      return {
        type: 'question',
        description: 'Drag & drop',
        subcontent_id: action.subContentId,
        order: i + 1,
        content_type: 'DragQuestion',
        timing: parseInt(duration.from),
      };
    }
    return {};
  });

export const parseToQuestion = (interactions) => {
  let answers = interactions.filter(
    (interaction) => interaction.type === 'answer',
  );
  const questions = interactions.filter(
    (interaction) => interaction.type === 'question',
  );
  let _questions: any[] = [];
  answers = groupBy(answers, 'order');
  for (var key in answers) {
    const data = answers[key];
    let question = {
      type: 'question',
      description: '',
      subcontent_id: '',
      order: key,
      content_type: 'Choice',
      timing: data[0].timing,
      answers: parseAnswers(data),
    };
    _questions = [..._questions, question];
  }
  return [...questions, ..._questions];
};

export const parseFromQuiz = (questions) => {
  const data = questions.map((ques, i) => {
    const { subContentId, params, metadata } = ques;
    const { contentType } = metadata;

    switch (contentType) {
      case 'Multiple Choice':
        return {
          type: 'question',
          description: params.question,
          subcontent_id: subContentId,
          order: i + 1,
          content_type: 'Choice',
          timing: i + 1,
          answers: params.answers.map((answer, index) => ({
            is_correct: answer.correct,
            description: answer.text,
            order: index + 1,
          })),
        };
      case 'Drag and Drop':
        return {
          type: 'question',
          description: 'Drag & drop',
          subcontent_id: subContentId,
          order: i + 1,
          content_type: 'DragQuestion',
          timing: i + 1,
        };
      case 'Fill in the Blanks':
        return parseFromFillBlanks(params.questions, subContentId);
      case 'Mark the Words':
        const { taskDescription, textField } = params;
        return parseFromMarkTheWords(taskDescription, textField, subContentId);
      case 'Drag the Words':
        return parseFromDragText(
          params.taskDescription,
          params.textField,
          subContentId,
        );
      default:
        return {};
    }
  });
  return data.flat();
};

export const parseFromMultipleChoice = (params, subContentId) => {
  return [
    {
      type: 'question',
      description: stripHtml(params.question),
      subcontent_id: subContentId,
      order: 1,
      content_type: 'Choice',
      timing: 1,
      answers: params.answers.map((answer, index) => ({
        is_correct: answer.correct,
        description: stripHtml(answer.text),
        order: index + 1,
      })),
    }
  ];
}

export const parseFromFillBlanks = (questions, subContentId) => {
  let answers_result: string[] = [];
  const question = questions.join(' ');
  let answers = getAnswersFromMarkTheWords(question);
  answers_result = splitAnswersFromQuestion(answers);

  return [
    {
      type: 'question',
      description: 'Fill in the blanks.',
      order: 1,
      content_type: 'fill_blank',
      timing: 1,
      subcontent_id: question.subContentId
        ? question.subContentId
        : subContentId,
      answers: answers_result.map((answer, index) => ({
        description: answer,
        order: index + 1,
        is_correct: true,
      })),
    },
  ];
};

export const parseFromMarkTheWords = (
  taskDescription,
  textField,
  subContentId,
) => {
  let answers = stripHtml(textField);
  let answers_result = getAnswersFromMarkTheWords(answers);

  return [
    {
      type: 'question',
      description: taskDescription,
      order: 1,
      content_type: 'mark_the_words',
      timing: 1,
      subcontent_id: subContentId,
      answers: answers_result.map((answer, index) => ({
        description: answer,
        order: index + 1,
        is_correct: true,
      })),
    },
  ];
};

export const parseFromDragText = (question, description, subContentId) => {
  let answers = stripHtml(description);
  let answers_result = getAnswersFromMarkTheWords(answers);
  return [
    {
      type: 'question',
      description: question,
      order: 1,
      content_type: 'drag_text',
      timing: 1,
      subcontent_id: subContentId,
      answers: answers_result.map((answer, index) => ({
        description: removeTipText(answer),
        order: index + 1,
        is_correct: true,
      })),
    },
  ];
};

export const parseColumnType = (contentData: any) => {
  let questionsCollection: any[] = [];

  contentData.map((content, i) => {
    const contentType = content.content?.metadata?.contentType;

    switch (contentType) {
      case 'Question Set': {
        if (content.content.params.questions) {
          const questions = parseFromQuiz(content.content.params.questions);
          questions['order'] = i + 1;
          questions['timimg'] = i + 1;
          questionsCollection = [...questionsCollection, ...questions];
        }
        break;
      }
      case 'Fill in the Blanks': {
        if (content.content.params.questions) {
          const questions = parseFromFillBlanks(
            content.content.params.questions,
            content.content.subContentId,
          );
          questionsCollection = [...questionsCollection, ...questions];
        }
        break;
      }
      case 'Drag and Drop': {
        const questions = {
          type: 'question',
          description: 'Drag & drop',
          subcontent_id: content.content.subContentId,
          order: i + 1,
          content_type: 'DragQuestion',
          timing: i + 1,
        };
        questionsCollection = [...questionsCollection, questions];
        break;
      }
      case 'Drag the Words': {
        const questions = parseFromDragText(
          content.content.params.taskDescription,
          content.content.params.textField,
          content.content.subContentId,
        );
        questionsCollection = [...questionsCollection, ...questions];
        break;
      }
      case 'Mark the Words': {
        const questions = parseFromMarkTheWords(
          content.content.params.taskDescription,
          content.content.params.textField,
          content.content.subContentId,
        );
        questionsCollection = [...questionsCollection, ...questions];
        break;
      }
      case 'Multiple Choice': {
        const questions = {
          type: 'question',
          description: content.content.params.question,
          subcontent_id: content.content.subContentId,
          order: i + 1,
          content_type: 'Choice',
          timing: i + 1,
          answers: content.content.params.answers.map((answer, index) => ({
            is_correct: answer.correct,
            description: answer.text,
            order: index + 1,
          })),
        };
        questionsCollection = [...questionsCollection, questions];
        break;
      }
    }
  });

  questionsCollection.map((question, index) => {
    question['order'] = index + 1;
    question['timing'] = index + 1;
  });
  return questionsCollection;
};

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const parseAnswers = (answers) =>
  answers.map((answer, index) => ({
    ...answer,
    order: index + 1,
  }));

const getAnswersFromMarkTheWords = (sentence: string) => {
  let answers: string[] = [];
  let results = sentence.split('*');
  for (let i = 1; i < results.length; i += 2) {
    answers.push(results[i]);
  }
  return answers;
};

const removeTipText = (answer) => {
  let result = answer.split(':')[0];
  if (result.includes('\\+') && result.includes('\\-')) {
    return result.split('\\+')[0];
  }
  return result;
};

// Remove html tags
const stripHtml = (html) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const splitAnswersFromQuestion = (question) => {
  let results: any = [];
  question.map(
    (result: any) =>
      (results = [...results, result.split(':')[0].split('/')[0]]),
  );
  return results;
};
